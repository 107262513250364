import { Field, getIn } from 'formik'
import InputHolder from '../InputHolder'
import * as propTypes from 'prop-types'
import React from 'react'
import 'styles/styles.module.scss'
import { SelectControl } from './helpers'
import Tag from '@util/tag'

export const Select = ({
    name,
    error,
    customwidth,
    customSelectStyle,
    inputClassName,
    inputStyle,
    labelStyle,
    listHeight,
    form,
    children,
    parentCss,
    setValue,
    renderOptions,
    containerClassName,
    optionsContainerStyle,
    onChange,
    ...props
}) => {
    const selectStyle = {
        inputClassName,
        customSelectStyle,
        inputStyle,
        labelStyle,
        optionsContainerStyle,
        listHeight
    }

    if (form)
        return (
            <Field name={name} className="z-[0]">
                {({ field, form: { errors, touched, setFieldValue } }) => {
                    return (
                        <InputHolder
                            inputName={name}
                            parentCss={parentCss}
                            error={
                                getIn(touched, field.name) &&
                                getIn(errors, field.name)
                            }
                        >
                            <SelectControl
                                id={field.name}
                                containerClassName={containerClassName}
                                listContainer={props.listContainer}
                                {...selectStyle}
                                {...field}
                                {...props}
                                onChange={(value) => {
                                    if (onChange) onChange(value)
                                    else {
                                        setFieldValue(field.name, value)
                                        setValue && setValue(value)
                                    }
                                }}
                            >
                                {renderOptions
                                    ? renderOptions?.map(
                                          ({ value, label, ...rest }) => (
                                              <Option key={value} {...rest}>
                                                  {label ?? value}
                                              </Option>
                                          )
                                      )
                                    : children}
                            </SelectControl>
                        </InputHolder>
                    )
                }}
            </Field>
        )

    return (
        <InputHolder inputName={name} parentCss={parentCss}>
            <SelectControl
                id={name}
                name={name}
                {...selectStyle}
                {...props}
                onChange={onChange}
                containerClassName={containerClassName}
            >
                {renderOptions
                    ? renderOptions?.map(({ value, label, ...rest }) => (
                          <Option key={value} {...rest}>
                              {label}
                          </Option>
                      ))
                    : children}
            </SelectControl>
        </InputHolder>
    )
}

export const Option = ({ children, className, ...props }) => {
    return (
        <Tag tagName="option" className={`capitalize ${className}`} {...props}>
            {children}
        </Tag>
    )
}

Select.propTypes = {
    name: propTypes.string,
    label: propTypes.string,
    error: propTypes.string
}

Option.propTypes = {
    value: propTypes.string
}
