import React from 'react'
import connectText from '@hooks/connectText'
import Input from '@components/input'
import PrimaryButton from '@components/shared/primary-buttons/primary-button'
import Textarea from '@components/Textarea'
import useTranslation from 'next-translate/useTranslation'
import UploadFileCustomized from '@components/upload-file-customized'
import { useDispatch } from 'noval'

const RequestCard = ({ submitForm, setFileBase64, setFileNameMain, setFileSizeMain, errors }) => {
    const { t } = useTranslation()
    const { dispatch } = useDispatch()
    const connect = connectText('common:operator.registration')

    return (
        <div className='grid md:grid-cols-2 gap-5 md:mx-4'>
            <Input
                name='fullName'
                label={t(connect('name'))}
                labelClass='text-[14px] lg:text-md'
                customclass='input-primary !w-full'
            />

            <Input
                name='mobile'
                label={t(connect('mobileNumber'))}
                type='tel'
                labelClass='text-[14px] lg:text-md'
                customclass='input-primary !w-full'
            />

            <Input
                name='email'
                label={t(connect('email'))}
                type='email'
                labelClass='text-[14px] lg:text-md'
                customclass='input-primary !w-full'
            />

            <Input
                name='companyName'
                label={t(connect('company'))}
                labelClass='text-[14px] lg:text-md'
                customclass='input-primary !w-full'
            />

            <Textarea
                name='message'
                label={t(connect('message'))}
                labelClass='text-[14px] lg:text-md'
                customClass='input-primary w-full h-24 !bg-transparent !border-0 focus:ring-0 focus:border-0'
                parentCss='md:col-span-2'
                inputHolderStyle='input-primary'
            />

            <UploadFileCustomized
                name='attachment'
                label={t('common:orders.review.attach')}
                maxSizeLabel={t(connect('maxSize20'))}
                format='pdf, doc, docx, ppt, pptx xls, xlsx'
                dropZoneStyle='bg-light-200 border-none'
                setFileBase64={setFileBase64}
                setFileNameMain={setFileNameMain}
                setFileSizeMain={setFileSizeMain}
                maxSize={20 * 1024 * 1024}
            />

            <div className={'flex justify-end md:col-span-2'}>
                <PrimaryButton
                    type='submit'
                    onClick={() => {
                        Object.keys(errors).length !== 0
                            ? dispatch('notify', {
                                  type: 'error',
                                  title: t('common:notifications.errorTitle'),
                                  info: t('common:notifications.registrationError')
                              })
                            : submitForm()
                    }}
                    customClassName='!px-16'
                >
                    {t(connect('send'))}
                </PrimaryButton>
            </div>
        </div>
    )
}

export default RequestCard
