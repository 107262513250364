import ReactSelect from 'react-select'
import React, { useState } from 'react'
import 'styles/styles.module.scss'
import { buildClass } from '@util/tag'

export const SelectControl = ({
    value,
    forceValue,
    label,
    labelStyle,
    onChange,
    isMulti,
    customSelectStyle,
    inputClassName,
    Icon,
    iconStyle,
    containerClassName,
    containerStyle = {},
    listContainer,
    listStyle,
    textStyle,
    ...props
}) => {
    const [menuIsOpen, setMenuIsOpen] = useState(false)
    const reactSelectOptions = React.Children.toArray(props?.children).map(
        (child) => ({
            label: child.props.children,
            value: child.props.value,
            ...child.props
        })
    )

    const reactSelectValue = reactSelectOptions.filter((option) =>
        [value].flat().includes(option.value)
    )

    const reactSelectOnChange = (selectedOptions) => {
        !props.persistOpen && setMenuIsOpen(false)
        return isMulti
            ? onChange(
                  selectedOptions.map((selectedOption) => selectedOption.value)
              )
            : onChange(selectedOptions.value)
    }
    const components = {
        IndicatorSeparator: null,
        Control: ({ children, ...rest }) => (
            <div
                data-test="select-controller"
                style={{
                    ...containerStyle
                }}
                className={buildClass(
                    'flex border-transparent bg-white text-[#ababab] cursor-pointer',
                    {
                        'bg-gray-300 opacity-50 cursor-not-allowed':
                            props?.disabled
                    },
                    containerClassName
                )}
                onClick={() => setMenuIsOpen(!rest?.menuIsOpen)}
            >
                {children}
            </div>
        ),
        SingleValue: ({ children }) => (
            <div className="absolute w-full mx-4">
                <div className={buildClass('flex flex-row', inputClassName)}>
                    {Icon ? (
                        <div className="flex items-center w-4 invisible" />
                    ) : null}
                    <div className="flex flex-col">
                        {label ? (
                            <div className="flex invisible whitespace-nowrap text-md">
                                {label}
                            </div>
                        ) : null}
                        <div
                            className={buildClass(
                                'border-0 lg:mx-4 mx-1 p-0 whitespace-nowrap',
                                'lg:!text-md !text-sm text-dark-400',
                                'focus:outline-none focus-visible:ring-0',
                                textStyle
                            )}
                            data-test={'select-value'}
                        >
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        ),
        Input: () => (
            <div className="flex flex-row gap-3 z-10 w-[11.5rem]">
                {Icon ? (
                    <div className="flex items-center">
                        <Icon className={buildClass(iconStyle)} />
                    </div>
                ) : null}
                <div className="flex flex-col gap-6">
                    {label ? (
                        <div
                            className={buildClass(
                                'flex text-dark-100 whitespace-nowrap text-md',
                                labelStyle
                            )}
                        >
                            {label}
                        </div>
                    ) : null}
                    <span className="border-0 p-0 bg-transparent focus:outline-none focus-visible:ring-0 lg:!text-md !text-sm" />
                </div>
            </div>
        ),
        MenuList: ({ children }) => (
            <>
                <div
                    className="fixed flex inset-0 z-[49]"
                    onClick={() => setMenuIsOpen(false)}
                />
                <div
                    className={buildClass(
                        'flex justify-start relative z-50 bg-white border border-[#e6e7e9]',
                        listContainer
                    )}
                >
                    <span
                        className="absolute mx-auto top-[-20px] right-[25px]"
                        style={{
                            borderWidth: '10px',
                            borderColor: 'transparent',
                            borderBottomColor: 'transparent'
                        }}
                    />
                    <div
                        className={buildClass(
                            'flex justify-start w-full max-h-full overflow-auto',
                            listStyle
                        )}
                    >
                        <div className="flex flex-col w-full">{children}</div>
                    </div>
                </div>
            </>
        )
    }

    if (props.searchable) {
        delete components.Input
    }

    return (
        <ReactSelect
            {...props}
            value={
                forceValue || (isMulti ? reactSelectValue : reactSelectValue[0])
            }
            isDisabled={props?.disabled}
            styles={selectStyle}
            onChange={reactSelectOnChange}
            options={reactSelectOptions}
            isMulti={isMulti}
            menuIsOpen={menuIsOpen}
            components={components}
            placeholder=""
        />
    )
}

export const dot = (color = 'transparent', dir = 'en') => ({
    alignItems: 'center',
    display: 'flex',
    ':before': {
        backgroundColor: color,
        borderRadius: 10,
        content: '" "',
        display: 'block',
        marginLeft: dir === 'ar' ? 8 : 'unset',
        marginRight: dir === 'en' ? 8 : 'unset',
        height: 10,
        width: 10
    }
})

export const selectStyle = {
    container: (base) => ({
        ...base
    }),
    input: () => ({
        border: '0px',
        outline: 'none',
        focus: {
            outline: 'none',
            border: '0px'
        },
        onfocus: {
            outline: 'none',
            border: '0px'
        }
    }),
    menu: () => ({
        position: 'absolute',
        marginTop: '3px',
        backgroundColor: 'white',
        width: '100%',
        zIndex: '55'
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        let background = '#fafafa'

        switch (true) {
            case isDisabled:
                background = undefined
                break

            case !isFocused:
                background = undefined
                break

            default:
                break
        }

        return {
            ...styles,
            backgroundColor: background,
            color: '#000',
            cursor: isDisabled ? 'not-allowed' : 'pointer',
            ':active': {
                ...styles[':active'],
                backgroundColor: background
            },
            ...(data.dotColor ? dot(data.dotColor, data.dir) : null)
        }
    }
}
