import RenderMeta from '@util/RenderMeta'
import React, { useState } from 'react'
import useTranslation from 'next-translate/useTranslation'
import LangIcon from 'public/img/lang.svg'
import setLanguage from 'next-translate/setLanguage'
import { buildClass } from '@util/tag'
import DownArrow from 'public/img/arrows/down-arrow.svg'
import RequestCard from '@components/request-service/request-card'
import AppCard, { collectionTypes } from '@components/request-service/app-card'
import ProfileCard from '@components/request-service/profile-card'
import MainLogo from 'public/img/logos/upsilon-logo-become-a-customer.svg'
import { Formik } from 'formik'
import * as Yup from 'yup'
import axios from 'axios'
import connectText from '@hooks/connectText'
import { useRouter } from 'next/router'
import LoadingFullScreen from '@components/shared/LoadingFullScreen'
import PrimaryButton from '@components/shared/primary-buttons/primary-button'
import { useDispatch } from 'noval'
import Nav from '@components/home/Nav'
import Footer from '@components/home/Footer'

const RequestService = () => {
    const { t, lang } = useTranslation()
    const {
        query: { source },
        push
    } = useRouter()
    const [isOpen, setIsOpen] = useState(false)
    const { dispatch } = useDispatch()
    const [submit, setSubmit] = useState(false)
    const [loading, setLoading] = useState(false)
    const [fileBase64, setFileBase64] = useState(null)
    const [fileNameMain, setFileNameMain] = useState()
    const [fileSizeMain, setFileSizeMain] = useState()
    const localSwitchTarget = lang === 'ar' ? 'en' : 'ar'
    const cardsStyle = 'bg-grey-600 md:w-[85%] md:p-6 p-5 w-[98%]'
    const connectErrors = connectText('common:researcher.errors')

    const toggleCard = () => {
        setIsOpen(!isOpen)
    }

    const validationSchema = Yup.object().shape({
        estimatedTime: Yup.string().required(t(connectErrors('required'))),
        collectionType: Yup.string().required(t(connectErrors('required'))),
        responsesNo: Yup.number().required(t(connectErrors('required'))),
        fullName: Yup.string().required(t(connectErrors('required'))),
        mobile: Yup.string()
            .matches(/^(5)\d+$/, t(connectErrors('startW5')))
            .matches(/^\d+$/, t(connectErrors('startW5')))
            .min(9, t(connectErrors('Digits9')))
            .max(9, t(connectErrors('Digits9')))
            .required(t(connectErrors('required'))),
        email: Yup.string()
            .email(t(connectErrors('emailInvalid')))
            .required(t(connectErrors('required'))),
        companyName: Yup.string().required(t(connectErrors('required'))),
        message: Yup.string().required(t(connectErrors('required'))),
        attachment: Yup.string().required(t(connectErrors('required')))
    })

    const handleSubmit = async (values) => {
        setLoading(true)
        delete values.attachment
        const getCollectionType = collectionTypes.find(({ name }) => name === values.collectionType.split('-')[0]).name

        let fileResponse = ''
        const file = fileBase64?.split('base64,')?.[0]

        if (file) {
            await axios
                .post(
                    process.env.NEXT_PUBLIC_SURVEY_REST_APT_URL + '/addFile',
                    {
                        file,
                        fileType: 'user_uploads',
                        fileName: fileNameMain
                    },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            Accept: '*/*'
                        }
                    }
                )
                .then((response) => {
                    fileResponse = response.data.fullPath
                })
                .catch((error) => {
                    return (
                        error &&
                        dispatch('notify', {
                            type: 'error',
                            title: t('common:notifications.error'),
                            info: t('common:notifications.fileNotUpload')
                        })
                    )
                })

            fileResponse &&
                (await axios
                    .post(
                        process.env.NEXT_PUBLIC_SURVEY_REST_APT_URL + '/requestService',
                        {
                            ...values,
                            source,
                            collectionType: getCollectionType,
                            attachment: fileResponse
                        },
                        {
                            headers: {
                                'Content-Type': 'application/json',
                                Accept: '*/*'
                            }
                        }
                    )
                    .then((res) => {
                        setLoading(false)
                        if (res) {
                            dispatch('notify', {
                                type: 'success',
                                title: t('common:notifications.successTitle')
                            })
                            setSubmit(true)
                        } else {
                            setLoading(false)
                            dispatch('notify', {
                                type: 'error',
                                title: t('common:notifications.errorTitle'),
                                info: t('common:notifications.generalError')
                            })
                        }
                    }))
        }
    }

    return (
        <>
            <RenderMeta title={t('common:requestService.requestTitle')} />
            <Nav />
            <Formik
                initialValues={{
                    fullName: '',
                    email: '',
                    mobile: '',
                    companyName: '',
                    message: '',
                    collectionType: '',
                    responsesNo: 0,
                    estimatedTime: '',
                    totalAmount: 0,
                    attachment: ''
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {({ submitForm, errors }) => {
                    return (
                        <div className='bg-no-repeat bg-cover w-full min-h-screen overflow-auto p-10 m-0 relative'>
                            <div
                                className='absolute top-4 rtl:left-7 ltr:right-7 cursor-pointer flex justify-center items-center gap-1'
                                onClick={() => setLanguage(localSwitchTarget)}
                            >
                                <h3 className='capitalize text-md text-white'>
                                    {t(`common:header.language.${localSwitchTarget}`)}
                                </h3>

                                <LangIcon
                                    className='relative cursor-pointer'
                                    fill='#FFF'
                                />
                            </div>

                            <div className='flex flex-col gap-6 items-center w-full'>
                                <MainLogo className='mt-5 lg:mt-10 mb-3 lg:mb-5' />

                                <div className='-mt-3 mb-4'>
                                    <h1 className='capitalize text-[14px] lg:text-md text-center'>
                                        {t('common:home.requestServiceDescr')}
                                    </h1>
                                </div>

                                <div className={buildClass(cardsStyle, '!p-0')}>
                                    <ProfileCard />
                                </div>

                                {loading ? (
                                    <div className='h-[60vh]'>
                                        <LoadingFullScreen />
                                    </div>
                                ) : !submit ? (
                                    <>
                                        <div className={cardsStyle}>
                                            <AppCard />
                                        </div>

                                        <div className={`${cardsStyle} grid gap-6`}>
                                            <div
                                                className='text-white cursor-pointer flex justify-between items-center'
                                                onClick={toggleCard}
                                            >
                                                <h2 className='text-lg text-dark-400 font-bold'>
                                                    {t('common:requestService.requestTitle')}
                                                </h2>

                                                <span>
                                                    <DownArrow className='transform scale-75' />
                                                </span>
                                            </div>

                                            {isOpen && (
                                                <RequestCard
                                                    submitForm={submitForm}
                                                    setFileBase64={setFileBase64}
                                                    setFileNameMain={setFileNameMain}
                                                    setFileSizeMain={setFileSizeMain}
                                                    fileSizeMain={fileSizeMain}
                                                    errors={errors}
                                                />
                                            )}
                                        </div>
                                    </>
                                ) : (
                                    <div className='flex flex-col gap-16 items-center h-[50vh] justify-between w-full py-10'>
                                        <div className='flex flex-col gap-10 items-center justify-center'>
                                            <div className='flex flex-col items-center justify-center'>
                                                <img
                                                    src='/img/GIFs/Done.gif'
                                                    className='w-24'
                                                />
                                            </div>

                                            <div className='text-md capitalize'>{t('common:requestService.sent')}</div>
                                        </div>

                                        <div className='flex flex-col items-center w-1/4'>
                                            <PrimaryButton
                                                customClassName='w-full h-16 !px-1'
                                                onClick={() => push('https://dem.dev')}
                                            >
                                                {t('common:survey.homePage')}
                                            </PrimaryButton>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    )
                }}
            </Formik>
            <Footer />
        </>
    )
}

export default RequestService
