import InputHolder from '@components/InputHolder'
import { Field, getIn } from 'formik'
import React, { useState } from 'react'
import Dropzone from 'react-dropzone'
import useTranslation from 'next-translate/useTranslation'
import { encode } from 'js-base64'
import Button from './button'
import { Dialog } from '@headlessui/react'
import connectText from '@hooks/connectText'

const connect = connectText('common:createSurvey')

const UploadFileCustomized = ({
    label,
    name,
    format,
    description,
    icon,
    maxSize,
    maxSizeLabel,
    setFile,
    setFileBase64,
    retrieveFile,
    retrieveFileState,
    setFileNameMain,
    setFileSizeMain,
    acceptedFilesRet,
    setAcceptedFilesRet,
    dropZoneStyle,
    disabled
}) => {
    const { t } = useTranslation()
    const [filePath, setFilePath] = useState('')
    const [errorMsg, setErrorMsg] = useState('')

    return (
        <Field name={name}>
            {({ field, form: { errors, touched, setFieldValue } }) => {
                return (
                    <Dropzone
                        data-test='upload-file'
                        maxSize={maxSize ?? 6145728} // 1MB
                        maxFiles={1}
                        disabled={disabled ?? false}
                        accept={['.pdf', '.doc', '.docx', '.ppt', '.pptx', '.xls', '.xlsx']}
                        onDropRejected={async () => {
                            setErrorMsg(await maxSizeLabel ?? t('common:orders.errors.6MBSizeError'))
                        }}
                        onDrop={async (acceptedFiles, fileRejections) => {
                            if (fileRejections.length === 0) {
                                setErrorMsg('')
                                await setFileNameMain(acceptedFiles[0].name)
                                await setFileSizeMain(acceptedFiles[0].size)

                                setFile && setFile(encode(await acceptedFiles?.[0]?.text()))
                                setFieldValue(name, encode(await acceptedFiles?.[0]?.text()))
                                setFilePath(URL.createObjectURL(acceptedFiles?.[0]))
                                setFileBase64(encode(await acceptedFiles?.[0]?.arrayBuffer()))
                                setAcceptedFilesRet && setAcceptedFilesRet(acceptedFiles?.[0])
                            }
                        }}
                    >
                        {({ getRootProps, getInputProps, acceptedFiles }) => {
                            // View attached files and options
                            return (
                                <InputHolder
                                    parentCss='md:col-span-2'
                                    label={label}
                                    error={getIn(touched, field.name) && getIn(errors, field.name)}
                                >
                                    <section className={` ${disabled ? 'cursor-not-allowed' : 'cursor-pointer'}`}>
                                        <div className='flex flex-col gap-6'>
                                            <div
                                                {...getRootProps({
                                                    className: `dropzone bg-body py-32 pt-9 pb-6 ${dropZoneStyle}  ${disabled ? '!cursor-not-allowed' : 'cursor-pointer'
                                                        }`
                                                })}
                                            >
                                                <input {...getInputProps()} />
                                                <div className=' overflow-hidden flex flex-col justify-center items-center p-5 gap-4'>
                                                    <div className='text-xs text-green font-normal text-center'>
                                                        {icon}
                                                    </div>
                                                    <div className='flex flex-col gap-1 text-[#8d8d8d] text-sm font-normal text-center'>
                                                        <div>{maxSizeLabel ?? t(connect('addons.maxSize'))}</div>
                                                        <div>{description}</div>
                                                        <div className='text-xs'>{format}</div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='text-red-600'>{errorMsg}</div>

                                            {acceptedFiles?.[0] || acceptedFilesRet ? (
                                                <DisplayAttached
                                                    filePath={filePath ?? acceptedFilesRet}
                                                    file={acceptedFiles[0] ?? acceptedFilesRet}
                                                />
                                            ) : (
                                                retrieveFileState && (
                                                    <DisplayAttached
                                                        file={retrieveFile}
                                                        filePath={retrieveFile.filePath}
                                                        fileSize={retrieveFile.fileSize}
                                                    />
                                                )
                                            )}
                                        </div>
                                    </section>
                                </InputHolder>
                            )
                        }}
                    </Dropzone>
                )
            }}
        </Field>
    )
}

const DisplayAttached = ({ file, filePath, fileSize }) => {
    const { t } = useTranslation()
    const [isOpen, setOpen] = useState(false)
    let size = 0
    let isPDF = false

    if (fileSize) {
        isPDF = true
        size = Math.round(fileSize / 1024)
    } else {
        size = Math.round(file?.size / 1024)
        isPDF = file.type === 'application/pdf'
    }
    return (
        <>
            <div className='w-full gap-2 text-dark-400 font-medium'>
                <div className='text-md'>{t(connect('launch.filesAttached'))} :</div>
                <div className='text-sm max-w-xs'>
                    {file?.name}, <span className='text-[#8d8d8d]'>({size} KB)</span>
                </div>
                <div className='flex flex-row items-center text-start text-sm mt-1 gap-3'>
                    <Button
                        transparent
                        customClassName='font-medium text-sm text-primary-600 rounded-none'
                    >
                        <a
                            target='_blank'
                            href={filePath}
                            rel='noreferrer'
                            download
                        >
                            {t(connect('launch.download'))}
                        </a>
                    </Button>
                    {isPDF ? (
                        <>
                            <div className='flex w-[1px] h-[20px] bg-primary-600' />
                            <Button
                                transparent
                                onClick={() => setOpen(true)}
                                customClassName='font-medium text-sm text-pr rounded-none'
                            >
                                {t(connect('launch.view'))}
                            </Button>
                        </>
                    ) : (
                        ''
                    )}
                </div>
            </div>
            {filePath && isPDF ? (
                <Dialog
                    open={isOpen}
                    onClose={() => setOpen(false)}
                >
                    <div
                        style={{ zIndex: 100 }}
                        className='fixed inset-0 flex justify-center items-center p-3'
                    >
                        <Dialog.Overlay className='fixed inset-0 bg-[#071512] bg-opacity-40' />
                        <div className='max-w-[80vw] max-h-[80vh] w-[100%] h-[100%] flex flex-col z-10 overflow-hidden'>
                            <iframe
                                width='100%'
                                height='100%'
                                src={filePath}
                            />
                        </div>
                    </div>
                </Dialog>
            ) : (
                ''
            )}
        </>
    )
}

export default UploadFileCustomized
