import Input from '@components/input'
import connectText from '@hooks/connectText'
import { useFormikContext } from 'formik'
import useTranslation from 'next-translate/useTranslation'
import React, { useEffect, useState } from 'react'
import { Option, Select } from '@components/select'

const AppCard = () => {
    const { t } = useTranslation()
    const connect = connectText('common:requestService')
    const inputStyle = 'w-full'
    const { values, setFieldValue } = useFormikContext()
    const [apps, setApps] = useState({
        responsesNo: 0,
        estimatedTime: 0
    })
    const [unitPrice, setUnitPrice] = useState(0)

    useEffect(() => {
        if (values?.collectionType) {
            setFieldValue('totalAmount', parseInt(values?.responsesNo) * generateUnitPrice(values?.estimatedTime, values?.collectionType?.split('-')[0]))
        }
    }, [unitPrice, apps])

    return (
        <div className='flex flex-col gap-6 md:mx-4'>
            <div className='text-center text-lg font-bold'>{t(connect('applications'))}</div>
            <div className='flex flex-col gap-6'>
                <div className='flex flex-row gap-4 xl:flex-nowrap flex-wrap'>
                    <div className='w-full'>
                        <div className='capitalize'>{t(connect('estimatedTime'))}</div>
                        <Select
                            form={true}
                            name='estimatedTime'
                            className='h-46 min-h-[45px] mt-3'
                            containerClassName='h-[2.80rem] bg-[#f2f2f2]'
                            value={values?.estimatedTime}
                            onChange={(value) => {
                                setFieldValue('estimatedTime', value)
                                setUnitPrice(value * parseInt(values?.collectionType.split('-')[1]))
                            }}
                        >
                            {estimatedTimes.map(({ name, value }) => (
                                <Option
                                    key={name}
                                    value={value}
                                >
                                    {t(connect('estimatedTimes.' + name))}
                                </Option>
                            ))}
                        </Select>
                    </div>
                    <div className='w-full'>
                        <div className='capitalize'>{t(connect('collectionType'))}</div>
                        <Select
                            form={true}
                            name='collectionType'
                            className='h-46 min-h-[45px] mt-3'
                            containerClassName='h-[2.80rem] bg-[#f2f2f2]'
                            value={values?.collectionType}
                            onChange={(value) => {
                                setUnitPrice(parseInt(value.split('-')[1]) * values?.estimatedTime)
                                setFieldValue('collectionType', value)
                            }}
                        >
                            {collectionTypes.map(({ name, value }) => (
                                <Option
                                    key={name}
                                    value={name + '-' + value}
                                >
                                    {t(connect(name))}
                                </Option>
                            ))}
                        </Select>
                    </div>
                    <div className='w-full'>
                        {inputs.map(({ name }) => (
                            <Input
                                name={name}
                                labelClass='text-[14px] lg:text-md mb-2 min-w-full'
                                customclass='input-primary !w-full'
                                parentCss={inputStyle}
                                label={t(connect(name))}
                                onChange={(e) => {
                                    const obj = apps
                                    obj[name] = e.target.value
                                    setApps(obj)
                                    setFieldValue(name, e.target.value)
                                    setFieldValue(
                                        'totalAmount',
                                        (e.target.value ? parseInt(e.target.value) : 0) * (generateUnitPrice(values?.estimatedTime, values?.collectionType?.split('-')[0]) ?? 0)
                                    )
                                }}
                            />
                        ))}
                    </div>
                </div>
                <div>
                    <div className='my-2 font-bold text-lg flex justify-start'>
                        {t(connect('total'))}: {values?.totalAmount}
                    </div>
                    <h4 className='text-dark-400'>{t(connect('priceEstimated'))}</h4>
                    <h4 className='text-dark-400'>{t(connect('tasxIncluded'))}</h4>
                </div>
            </div>
        </div>
    )
}

const generateUnitPrice = (estimatedTime, type) => {
    if (type === 'call') {
        return generateCallsCost(estimatedTime)
    } else if (type === 'direct') {
        return generateDirectCost(estimatedTime)
    } else if (type === 'distribute') {
        return generateDistributeCost(estimatedTime)
    }
}

const generateCallsCost = (estimatedTime) => {
    switch (estimatedTime) {
        case 1:
            return 35
        case 2:
            return 50
        case 3:
            return 75
        case 4:
            return 100
        case 5:
            return 125
        default:
            break
    }
}

const generateDirectCost = (estimatedTime) => {
    switch (estimatedTime) {
        case 1:
            return 50
        case 2:
            return 70
        case 3:
            return 105
        case 4:
            return 140
        case 5:
            return 175
        default:
            break
    }
}

const generateDistributeCost = (estimatedTime) => {
    switch (estimatedTime) {
        case 1:
            return 10
        case 2:
            return 20
        case 3:
            return 30
        case 4:
            return 40
        case 5:
            return 50
        default:
            break
    }
}

const inputs = [
    {
        name: 'responsesNo'
    }
]

const estimatedTimes = [
    {
        name: 'less5',
        value: 1
    },
    {
        name: 'between5-15',
        value: 2
    },
    {
        name: 'between16-30',
        value: 3
    },
    {
        name: 'between31-60',
        value: 4
    },
    {
        name: 'bigOneHour',
        value: 5
    }
]

export const collectionTypes = [
    {
        name: 'direct',
        value: 50
    },
    {
        name: 'distribute',
        value: 10
    },
    {
        name: 'call',
        value: 35
    }
]

export default AppCard
